import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {SessionStorageService} from '../../modules/storage/session-storage.service';
import {PatientCreatedResponse} from '../../modules/patient-repository/entities/patient-created-response';
import {Patient, QueryPatient} from '../../modules/patient-repository/entities/patient';
import {Practice} from '../../modules/practice-repository/entities/practice';
import {UserTabsRepositoryService} from 'src/app/modules/user-tabs-repository/user-tabs-repository.service';
import {AuthenticationService} from '../auth/authentication.service';
import {CoreService} from './core.service';
import {WebsocketService} from './websocket.service';
import {sanitizeUrlName} from '../../utilities/url-utils';
import {CoreEventsService} from './core-events.service';
import {LocalStorageService} from '../../modules/storage/local-storage.service';
import {CreatedPatient} from '../../modules/patient-repository/entities/CreatedPatient';

@Injectable({
  providedIn: 'root'
})
export class AppTabsService implements OnDestroy {

  private channelName = '';
  private readonly eventName = 'tabs_updated';
  constructor(
    private router: Router,
    private coreService: CoreService,
    private coreEventService: CoreEventsService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private userTabsRepository: UserTabsRepositoryService,
    private authService: AuthenticationService,
    private pushService: WebsocketService
  ) {
    this.initializeTabs();
    this.authSubscription = this.authService.events$.subscribe((event) => {
      if (event.type === 'login') {
        this.initializeTabs();
      }
    });
    this.coreSubscription = this.coreEventService.events$.subscribe((event) => {
      if (event.type === 'progress_complete') {
        this.initializeTabs();
      }
      if (event.type === 'settings_loaded') {
        this.setupMaxTabs();
      }
    });
    this.setupMaxTabs();
  }

  private readonly authSubscription: Subscription;
  private readonly coreSubscription: Subscription;
  private tabBehaviourSubject: BehaviorSubject<TabEvent> = new BehaviorSubject<TabEvent>(new TabEvent('tab_service_initialized'));
  events$ = this.tabBehaviourSubject.asObservable();
  private currentPractice: Practice = null;
  private defaultRoute = '/dashboard';
  private tabs: Tab[] = [];
  private activeTabIndex = -1;
  private maxTabs = -1;
  private defaultUserPath = '';


  private static areTabsEqual(tab1: Tab, tab2: Tab): boolean {
    return tab1 !== null && tab2 !== null &&
      tab1.patientId === tab2.patientId &&
      tab1.profileId === tab2.profileId;
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
    this.coreSubscription?.unsubscribe();
    this.pushService.unsubscribe(this.channelName, this.eventName);
  }

  public openTabWithNewPatient(patient: CreatedPatient) {
    let route = `/patients/switch/${patient.profileId}/${patient.name.sanitizeUrl()}`;
    if (this.defaultUserPath.length > 0) {
      route += '/' + this.defaultUserPath;
    }
    this.openTab(patient.name, patient.id, patient.profileId, route);
  }

  public openTabWithPatientInEditMode(patient: QueryPatient) {
    const route = `/patients/switch/${patient.profileId}/${patient.name.sanitizeUrl()}/edit`;
    this.openTab(patient.name, patient.id, patient.profileId, route);
  }

  public openTabWithSearchPatient(patient: QueryPatient) {
    let route = `/patients/switch/${patient.profileId}/${sanitizeUrlName(patient.name)}`;
    if (this.defaultUserPath.length > 0) {
      route += '/' + this.defaultUserPath;
    }
    this.openTab(patient.name, patient.id, patient.profileId, route);
  }

  public openTabWithPatient(patient: Patient) {
    let route = `/patients/switch/${patient.profileId}/${sanitizeUrlName(patient.name)}`;
    if (this.defaultUserPath.length > 0) {
      route += '/' + this.defaultUserPath;
    }
    this.openTab(patient.name, patient.id, patient.profileId, route);
  }

  private openTab(title: string, patientId: number, profileId: number, route: string) {
    const tab = new Tab(title, patientId, profileId, route);
    if (!this.tabExists(tab)) {
      this.tabs.push(tab);
    }
    this.saveTabs();
    this.activateTab(this.tabIndex(tab));
    if (this.maxTabs !== -1 && this.tabs.length > this.maxTabs) {
      this.closeTab(0);
    }
  }

  public closeTab(index: number) {
    const isCurrentTabPrimary = this.activeTabIndex === index;
    const previousIndex = index === 0 ? index + 1 : index - 1;
    const isLastTabClosing = this.tabs.length === 1;
    const removedTab = this.tabs[index];
    this.tabs.splice(index, 1);
    this.saveTabs();
    this.removePatientFromStorage(removedTab.profileId);
    if (isCurrentTabPrimary && !isLastTabClosing) {
      this.activateTab(previousIndex);
    }
    if (this.tabs.length === 0) {
      this.router.navigateByUrl(this.defaultRoute);
    }
    this.tabBehaviourSubject.next(new TabEvent('tab_closed'));
  }

  public activateTabByProfileId(id: number) {
    // tslint:disable-next-line:triple-equals
    this.activeTabIndex = this.tabs.findIndex(t => t.profileId == id);
  }

  private activateTab(index: number) {
    if (!this.tabs[index]) { return; }
    const route = this.tabs[index].route;
    this.activeTabIndex = index;
    this.tabBehaviourSubject.next(new TabEvent('tab_activated'));
    this.router.navigateByUrl(route);
  }

  public updateTabRoute(profileId: number, name: string, submodules: string[]) {
    if (!this.hasTabs() || !this.hasActiveTab()) { return; }
    setTimeout(() => {
      const index = this.tabs.findIndex(t => t.profileId === profileId);
      if (index > -1) {
        let route = `patients/switch/${profileId}/${sanitizeUrlName(name)}`;
        submodules.forEach(module => {
          route += `/${module}`;
        });
        const tab = this.tabs[index];
        tab.route = route;
        this.tabs[index] = tab;
      }
    });
  }

  public getTabs(): Tab[] {
    return this.tabs;
  }

  public hasTabs(): boolean {
    return this.tabs.length > 0;
  }

  public getActiveTab(): number {
    return this.activeTabIndex;
  }

  public hasActiveTab(): boolean {
    return this.activeTabIndex > -1;
  }

  private tabIndex(tab: Tab): number {
    for (let i = 0; i < this.tabs.length; i++) {
      if (AppTabsService.areTabsEqual(this.tabs[i], tab)) {
        return i;
      }
    }
    return -1;
  }

  private tabExists(referenceTab: Tab): boolean {
    for (const tab of this.tabs) {
      if (AppTabsService.areTabsEqual(referenceTab, tab)) {
        return true;
      }
    }
    return false;
  }

  private initializeTabs() {
    if (!this.authService.isAuthenticated() || !this.coreService.isReady) { return; }
    this.currentPractice = this.localStorage.getItem('selected_practice');
    this.tabs = this.sessionStorage.getItem(this.getUserTabsKey(), []);
    this.cleanupTabs();
    this.loadTabs();
    this.channelName = `xmedical_practice_${this.coreService.selectedPractice.id}_user_${this.coreService.currentUser.userId}_tabs_events`;
    this.pushService.subscribe(this.channelName, this.eventName, (data) => {
      this.loadTabs();
    });
  }

  private loadTabs() {
    this.userTabsRepository.getTabs().subscribe(tabs => {
      this.tabs = tabs;
      this.sessionStorage.setItem(this.getUserTabsKey(), this.tabs);
      this.cleanupTabs();
    }, error => {
      console.error(error);
    });
  }

  private cleanupTabs() {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i] == null) {
        this.tabs.splice(i, 1);
      }
    }
    const extraTabs = this.tabs.length - this.maxTabs;
    this.tabs.splice(0, extraTabs);
    this.activeTabIndex = this.hasTabs() ? 0 : -1;
    this.tabBehaviourSubject.next(new TabEvent('tabs_loaded'));
  }

  private removePatientFromStorage(profileId: number) {
    const patientStorageKey = this.getStorageKey(profileId);
    this.sessionStorage.deleteItem(patientStorageKey);
  }

  private getStorageKey(profileId: number): string {
    return 'patient-key-' + profileId + '-' + this.currentPractice.id;
  }

  private getUserTabsKey(): string {
    return 'user-tabs-' + this.currentPractice.id;
  }

  private saveTabs() {
    this.sessionStorage.setItem(this.getUserTabsKey(), this.tabs);
    this.userTabsRepository.updateTabs(this.tabs).subscribe(() => {
    }, error => {
      this.coreService.showErrorToast(error);
      console.error(error);
    });
  }

  private setupMaxTabs() {
    this.maxTabs = this.coreService.settings?.maxTabs ?? -1;
  }

}

export class Tab {
  patientId: number;
  profileId: number;
  title: string;
  route: string;

  constructor(title: string, patientId: number, profileId: number, route: string) {
    this.title = title;
    this.route = route.split(' ').join('-');
    this.patientId = patientId;
    this.profileId = profileId;
  }
}

export type TabEventType =
  | 'tab_service_initialized'
  | 'tabs_loaded'
  | 'tab_updated'
  | 'tab_created'
  | 'tab_closed'
  | 'tab_activated';

export class TabEvent {
  constructor(readonly type: TabEventType) {
  }
}
